@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .active-menu-link {
    @apply flex items-center justify-start w-full p-4 my-2 font-thin text-blue-500 uppercase transition-colors duration-200 border-r-4 border-blue-500 bg-gradient-to-r from-white to-blue-100;
  }
  .menu-link {
    @apply flex items-center justify-start w-full p-4 my-2 font-thin text-gray-500 uppercase transition-colors duration-200 hover:text-blue-500;
  }
  .has-submenu ul {
    @apply hidden border-b border-blue-500 bg-gray-50;
  }
  .has-submenu ul li {
    @apply ml-3;
  }
  .has-submenu:hover ul {
    @apply block;
  }

  .truncate-wrapper {
    position: relative;
  }

  .truncate-wrapper:hover .truncate {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    overflow: visible;
    white-space: normal;
    word-wrap: break-word;
    z-index: 10;
    max-width: 100%;
    background-color: #fff;
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
    display: block;
  }

  .h-screen-80 {
    height: 80vh;
  }
  .shadow-t-lg {
    box-shadow: 0 -10px 10px 0px rgb(0 0 0 / 5%);
  }
  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: #efefef;
    box-shadow: inset 1px 1px 10px #c4c4c4;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #2563eb;
    border-radius: 9em;
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #709dff;
  }
  .product-description ul {
    list-style: disc;
    padding-left: 30px;
  }
  .gpt-btn {
    background: #bcbcbc;
  }
  .loading {
    transition: all 0.15s ease-in-out;
    animation: loading 0.5s ease-in-out;
    width: 90%;
    border-radius: 0 1.5rem 1.5rem 0;
  }
  @keyframes loading {
    0% {
      width: 35%;
    }
    100% {
      width: 90%;
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
